import styled from 'styled-components'
import ButtonComponent from '../../Button/Button'
import UniversalLink from '../../UniversalLink'

import Section from '../../Section'

const BlockPerformanceTableSection = styled(Section)`
	position: relative;
	display: flex;
	padding-top: ${props => props.paddingTop}px;
	padding-bottom: ${props => props.paddingBottom}px;
	margin-bottom: 80px;
	flex-direction: column;
	position: relative;
	z-index: 999;
	bottom: ${props => (props.variant === 'header' ? '55px' : 0)};
`

const PerformanceTableHeader = styled.div`
	background-color: #656565;
	color: #fff;
	padding: 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	display: flex;
	flex-direction: column;
`

const PerformanceTableTitle = styled.span``

const PerformanceTableSubtitle = styled.span`
	font-size: 0.8rem;
	color: #cccccc;
	margin: 0;
	line-height: 1;
`

const ContactLink = styled(UniversalLink)``

const PerformanceFormWrapper = styled.div`
	background-color: #b0bec5;
	padding: 20px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	min-height: 85px;
	z-index: 9999;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
	}
`

const SelectField = styled.select`
	border: none;
	border-radius: 5px;
	margin: 0 0 10px;
	height: 40px;
	width: 100%;

	/* @media (min-width: ${props => props.theme.sizes.maxTablet}) {
		width: 150px;
		height: auto;
		margin: 0;
	} */

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		width: 18%;
		height: auto;
		margin: 0;
	}
`

const Button = styled(ButtonComponent)`
	margin: 10px 0px;
`

const BuyIframeContainer = styled.div`
	top: 150px;
	position: absolute;
	right: 30px;
	height: 350px;

	display: flex;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		height: 290px;
		top: 0;
		right: 0;
	}
`

const CurvedText = styled.span`
	position: relative;
	bottom: 15px;
	right: 25px;
	font-style: italic;
`

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-self: self-end;
`

export {
	BlockPerformanceTableSection,
	PerformanceTableHeader,
	PerformanceTableTitle,
	PerformanceTableSubtitle,
	ContactLink,
	PerformanceFormWrapper,
	SelectField,
	Button,
	BuyIframeContainer,
	CurvedText,
	TextWrapper
}
