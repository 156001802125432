import React from 'react'
import styled from 'styled-components'

const CurvedArrow = () => {
	return (
		<CurvedArrowContainer>
			<Point />
			<Curve />
		</CurvedArrowContainer>
	)
}
export default CurvedArrow

const CurvedArrowContainer = styled.div`
	position: absolute;
	margin: 0 auto;
	width: 100px;
	right: -50px;
	rotate: 180deg;
`

const Curve = styled.div`
	border: 2px solid #be5f4b;
	border-color: transparent transparent transparent #0885c5;

	width: 220px;
	border-radius: 230px 0 0 150px;

	height: 390px;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		height: 290px;
	}
`

const Point = styled.div`
	position: absolute;

	left: 77px;
	top: 69px;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		left: 58px;
		top: 62px;
	}

	&:before {
		top: -25px;
		left: -17px;
		transform: rotate(-86deg);
		border: 1px solid #0885c5;
		height: 25px;
		content: '';
		position: absolute;
	}

	&:after {
		top: -12px;
		left: -7px;
		transform: rotate(192deg);
		border: 1px solid #0885c5;
		height: 25px;
		content: '';
		position: absolute;
	}
`
