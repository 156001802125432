import React from 'react'

import LinkOrButton from '../../LinkOrButton'
import Title from '../../TitleMedium'
import HTMLContent from '../../HTMLContent'
import Icon from '../../Icon'

import {
	TextIconBlocksSection,
	ContentWrapper,
	IconsWrapper,
	IconBox,
	TitleBox,
	IconTitle
} from './BlockTextIconBlocks.styled'

const BlockTextIconBlocks = data => {
	return (
		<TextIconBlocksSection
			paddingBottom={data.paddingBottom}
			paddingTop={data.paddingTop}
			position={data.imagePosition}
		>
			<ContentWrapper position={data.imagePosition}>
				<Title
					dangerouslySetInnerHTML={{
						__html: data.title
					}}
				/>
				<HTMLContent
					dangerouslySetInnerHTML={{
						__html: data.text
					}}
				/>

				{data.active && <LinkOrButton type={data.linkOrButton} url={data.link?.url} text={data.linkButtonText} />}
			</ContentWrapper>
			<IconsWrapper>
				{data.icons.map((icon, i) => {
					return (
						<IconBox key={i}>
							<Icon color="primary" width="50px" height="50px" icon={icon.fontawesomeIconName} />
							<TitleBox>
								<IconTitle>{icon.iconTitle}</IconTitle>
							</TitleBox>
						</IconBox>
					)
				})}
			</IconsWrapper>
		</TextIconBlocksSection>
	)
}

export default BlockTextIconBlocks
