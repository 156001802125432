import React, { useEffect, useState } from 'react'
import fetch from 'node-fetch'

import {
	BlockPerformanceTableSection,
	PerformanceTableHeader,
	PerformanceTableTitle,
	PerformanceTableSubtitle,
	ContactLink,
	PerformanceFormWrapper,
	SelectField,
	Button,
	BuyIframeContainer,
	CurvedText,
	TextWrapper
} from './BlockPerformanceTable.styled'
import { sortBy } from 'lodash'
import CurvedArrow from '../../CurvedArrow/CurvedArrow'
import LinkOrButton from '../../LinkOrButton'
import { isMobileOnly } from 'react-device-detect'

/* eslint-disable */
const BlockPerformanceTable = data => {
	const [fetchedBrands, setFetchedBrands] = useState([])
	const [fetchedModels, setFetchedModels] = useState([])
	const [fetchedGenerations, setFetchedGenerations] = useState([])
	const [fetchedProduct, setFetchedProduct] = useState([])

	const [chosenBrand, setChosenBrand] = useState({ name: '', id: '' })
	const [chosenModel, setChosenModel] = useState({ name: '', id: '' })
	const [chosenGeneration, setChosenGeneration] = useState({ name: '', id: '' })
	const [chosenProduct, setChosenProduct] = useState({ name: '', id: '', slug: '' })

	useEffect(async () => {
		// eslint-disable-next-line no-undef
		const brands = await fetch(`${process.env.GATSBY_API_URL}/brands`, {
			method: 'get'
		}).then(res => res.json())
		setFetchedBrands(brands)
	}, [])

	useEffect(() => {
		const brand = JSON.parse(window.localStorage.getItem('brandValue'))
		if (brand) {
			setTimeout(() => setChosenBrand(brand), 100)
		}

		const model = JSON.parse(window.localStorage.getItem('modelValue'))
		if (model) {
			setTimeout(() => setChosenModel(model), 150)
		}

		const generation = JSON.parse(window.localStorage.getItem('generationValue'))
		if (generation) {
			setTimeout(() => setChosenGeneration(generation), 200)
		}

		const product = JSON.parse(window.localStorage.getItem('productValue'))
		if (product) {
			setTimeout(() => setChosenProduct(product), 250)
		}
	}, [])

	useEffect(async () => {
		if (!chosenBrand?.id) {
			return
		}
		// eslint-disable-next-line no-undef
		const models = await fetch(`${process.env.GATSBY_API_URL}/modelsByBrand?brandId=${chosenBrand.id}`, {
			method: 'get'
		}).then(res => res.json())

		setFetchedModels(models)
		setFetchedGenerations([])
		setFetchedProduct([])
	}, [chosenBrand?.id])

	useEffect(async () => {
		if (!chosenModel?.id) {
			return
		}
		// eslint-disable-next-line no-undef
		const generations = await fetch(`${process.env.GATSBY_API_URL}/generationsByModel?modelId=${chosenModel.id}`, {
			method: 'get'
		}).then(res => res.json())

		const sortedGenerations = sortBy(generations, [
			generation => {
				return generation.name.split('-').splice(-1)
			}
		])

		setFetchedGenerations(sortedGenerations)
		setFetchedProduct([])
	}, [chosenModel?.id])

	useEffect(async () => {
		if (!chosenGeneration?.id) {
			return
		}
		// eslint-disable-next-line no-undef
		const product = await fetch(
			`${process.env.GATSBY_API_URL}/productsByGenerationId?generationId=${chosenGeneration.id}`,
			{
				method: 'get'
			}
		).then(res => res.json())
		setFetchedProduct(product)
	}, [chosenGeneration?.id])

	const handleBrandChange = e => {
		const id = e.target.children[e.target.selectedIndex].id
		const dumpId = e.target.childNodes[e.target.selectedIndex].getAttribute('dumpid')

		window.localStorage.setItem('brandValue', JSON.stringify({ name: e.target.value, id: id, dumpid: dumpId }))
		setChosenBrand({ name: e.target.value, id: id })
	}

	const handleModelChange = e => {
		const id = e.target.children[e.target.selectedIndex].id
		window.localStorage.setItem('modelValue', JSON.stringify({ name: e.target.value, id: id }))
		setChosenModel({ name: e.target.value, id: id })
	}

	const handleGenerationChange = e => {
		const id = e.target.children[e.target.selectedIndex].id
		window.localStorage.setItem('generationValue', JSON.stringify({ name: e.target.value, id: id }))
		setChosenGeneration({ name: e.target.value, id: id })
	}

	const handleProductChange = e => {
		const index = e.target.selectedIndex
		const el = e.target.childNodes[index]
		const id = el.getAttribute('id')
		const slug = el.getAttribute('slug')
		window.localStorage.setItem('productValue', JSON.stringify({ name: e.target.value, id: id, slug: slug }))
		setChosenProduct({ name: e.target.value, id: id, slug: slug })
	}

	return (
		<BlockPerformanceTableSection
			paddingBottom={data.paddingBottom}
			paddingTop={data.paddingTop}
			variant={data.variant}
		>
			<PerformanceTableHeader>
				<PerformanceTableTitle>Selecteer hier uw voertuig</PerformanceTableTitle>
				<PerformanceTableSubtitle>
					Staat uw voertuig er niet tussen? Neem dan <ContactLink to="/contact">contact</ContactLink> op.
				</PerformanceTableSubtitle>
			</PerformanceTableHeader>

			<PerformanceFormWrapper>
				<SelectField value={chosenBrand?.name} onChange={e => handleBrandChange(e)}>
					<option value="0">Selecteer uw Merk</option>
					{fetchedBrands.map(brand => (
						<option id={brand._id} key={brand._id} dumpid={brand.dumpId} value={brand.name}>
							{brand.name}
						</option>
					))}
				</SelectField>
				<SelectField value={chosenModel?.name} onChange={e => handleModelChange(e)}>
					<option value="0">Selecteer uw Model</option>
					{fetchedModels.length >= 1 &&
						fetchedModels.map(model => (
							<option id={model._id} key={model._id} value={model.name}>
								{model.name}
							</option>
						))}
				</SelectField>
				<SelectField value={chosenGeneration?.name} onChange={e => handleGenerationChange(e)}>
					<option value="0">Selecteer uw Bouwjaar</option>
					{fetchedGenerations.length >= 1 &&
						fetchedGenerations.map(generation => (
							<option id={generation._id} key={generation._id} value={generation.name}>
								{generation.name.replace('&gt;', '>').replace('&lt;', '<')}
							</option>
						))}
				</SelectField>
				<SelectField value={chosenProduct?.name} onChange={e => handleProductChange(e)}>
					<option value="0">Selecteer uw Type</option>
					{fetchedProduct.length >= 1 &&
						fetchedProduct.map(product => (
							<option slug={product.slug} id={product._id} key={product._id} value={product.name}>
								{product.name}
							</option>
						))}
				</SelectField>
				<Button
					variant="tertiary"
					url={chosenProduct?.slug && `/chiptunen/${chosenProduct.slug}`}
					text="Bekijk vermogen"
					padding="10px 20px"
					disabled={!chosenBrand?.id || !chosenModel?.id || !chosenGeneration?.id || !chosenProduct?.id}
				/>
				<Button
					variant="primary"
					url="/offerte-aanvragen"
					text="Offerte"
					padding="10px 20px"
					margin={isMobileOnly ? '10px 0 0 0' : '0'}
					disabled={!chosenBrand?.id || !chosenModel?.id || !chosenGeneration?.id || !chosenProduct?.id}
				/>
			</PerformanceFormWrapper>
			<BuyIframeContainer>
				<CurvedArrow />
				<TextWrapper>
					<CurvedText>Wilt u deze tabel ook op uw website?</CurvedText>
					<CurvedText>
						<a href="/iframe-database-tuning-specificaties">Klik hier</a> voor meer informatie
					</CurvedText>
				</TextWrapper>
			</BuyIframeContainer>
		</BlockPerformanceTableSection>
	)
}

export default BlockPerformanceTable
