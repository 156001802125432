import styled from 'styled-components'

import Section from '../../Section'

const TextIconBlocksSection = styled(Section)`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: ${props => props.paddingTop}px;
	padding-bottom: ${props => props.paddingBottom}px;
	flex-direction: column;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: ${props => (props.position ? 'row-reverse' : 'row')};
	}
`

const ContentWrapper = styled.div`
	z-index: 2;
	max-width: 100%;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		padding: 0 30px 0 0;
		max-width: 50%;
	}
`

const IconsWrapper = styled.div`
	display: flex;
	flex-flow: wrap;
	width: 100%;
	justify-content: flex-end;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		justify-content: space-between;
	}
`

const IconBox = styled.div`
	display: flex;
	width: 100%;
	/* width: 300px; */
	height: 150px;
	border: 1px solid #cbcbcb;
	border-radius: 10px;
	padding: 20px;
	margin: 15px 0 15px;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	max-width: 100%;
	/* margin-right: 10px; */

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		justify-content: space-between;
		max-width: 46%;
		margin-right: 10px;
	}

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		max-width: 48%;
		margin-right: 10px;
	}
`

const IconTitle = styled.h4`
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
`

const TitleBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 50%;
	display: flex;
	position: absolute;
	bottom: 0;
	background: #b0bec5;
	border-radius: 0 0 9px 9px;
`

export { TextIconBlocksSection, ContentWrapper, IconsWrapper, IconBox, TitleBox, IconTitle }
